import request from '@/utils/request'


// 查询意见反馈列表
export function listUserFeedback(query) {
  return request({
    url: '/platform/user-feedback/list',
    method: 'get',
    params: query
  })
}

// 查询意见反馈分页
export function pageUserFeedback(query) {
  return request({
    url: '/platform/user-feedback/page',
    method: 'get',
    params: query
  })
}

// 查询意见反馈详细
export function getUserFeedback(data) {
  return request({
    url: '/platform/user-feedback/detail',
    method: 'get',
    params: data
  })
}

// 新增意见反馈
export function addUserFeedback(data) {
  return request({
    url: '/platform/user-feedback/add',
    method: 'post',
    data: data
  })
}

// 修改意见反馈
export function updateUserFeedback(data) {
  return request({
    url: '/platform/user-feedback/edit',
    method: 'post',
    data: data
  })
}

// 删除意见反馈
export function delUserFeedback(data) {
  return request({
    url: '/platform/user-feedback/delete',
    method: 'post',
    data: data
  })
}

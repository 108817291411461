<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
        <a-form-model-item label="刷手號" prop="userNum">
          {{form.userNum}}
        </a-form-model-item>
      <a-form-model-item label="昵稱" prop="nickname">
        <span>{{ form.userName }}</span>
      </a-form-model-item>
      <a-form-model-item label="反馈類型" prop="typeName">
        <span>{{ form.typeName }}</span>
      </a-form-model-item>
      <a-form-model-item label="反馈图片" prop="avatar">
         <span  v-if="form.imgUrls != null && form.imgUrls != ''">
            <img @click="imgShow(imgUrl)" style="width: 55px;height: 55px;margin-left: 5px" preview="反馈图片" v-for="(imgUrl, index) in form.imgUrls.split(',')" :src="imgUrl" v-if="form.imgUrls != null && form.imgUrls != ''">
          </span>
        <span v-else>
            暂无图片
          </span>
      </a-form-model-item>
      <a-form-model-item label="反馈内容" prop="content">
        <span>{{ form.content }}</span>
      </a-form-model-item>
      <a-form-model-item label="反馈时间" prop="createTime">
        <span>{{ form.createTime }}</span>
      </a-form-model-item>
      <a-form-model-item label="处理狀態" prop="processStatus" >
        <a-select v-model="form.processStatus" placeholder="处理狀態">
          <a-select-option :value="2">已处理</a-select-option>
          <a-select-option :value="3">暂不处理</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="处理结果" prop="processResult" >
        <a-input v-model="form.processResult" placeholder="请输入处理结果" type="textarea" allow-clear />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getUserFeedback, addUserFeedback, updateUserFeedback } from '@/api/platform/userFeedback'
import Editor from '@/components/Editor'
import {imgShow} from "@/utils/ruoyi";

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    Editor
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        userId: null,

        type: null,

        content: null,

        processStatus: 0,

        processResult: null,

        imgUrls: null,

        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        userId: [
          { required: true, message: '用戶id，外键{user.id}不能为空', trigger: 'blur' }
        ],
        type: [
          { required: true, message: '反馈類型：1-意见建议、2-故障报告、3-其他问题不能为空', trigger: 'change' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    imgShow(imgUrl){
      imgShow(imgUrl)
    },
    onClose () {
      this.open = false
      this.form.processStatus = 1
      this.reset();
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.form.processStatus = 1
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        userId: null,
        type: null,
        content: null,
        processStatus: 0,
        processResult: null,
        imgUrls: null,
        createTime: null,
        remark: null,
      }
   /*   this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })*/
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      // getUserFeedback({"id":id}).then(response => {
      //   this.form = response.data
      //   this.form.processStatus = 2
      //   this.open = true
      //   this.formTitle = '修改'
      // })
      this.form = row
      this.form.processStatus = 2
      this.open = true
      this.formTitle = '修改'
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateUserFeedback(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addUserFeedback(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
